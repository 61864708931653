<template>
  <div>
    <breadcrumbs :items="breadcrumbs"/>

    <div class="ml-6">
      <v-row class="mt-1">
        <v-col cols="11">
          <v-btn @click="showForm()" color="success">Add Waste Partner</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11">
          <v-data-table
              dense
              :headers="itemsHeaders"
              :items="items"
              item-key="name"
              class="elevation-1"
              :loading="flagLoadingItems"
              @pagination="setPagination($event)"
          >
            <template v-slot:loading>
              <div class="pb-5">
                <v-progress-circular
                    :rotate="360"
                    :size="30"
                    :width="1"
                    color="success"
                    indeterminate
                    class="mt-5"
                >
                </v-progress-circular>
              </div>
            </template>
            <template v-slot:item.actions="{ item, index }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small color="success" @click="showForm(index)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Update</span>
              </v-tooltip>

              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small color="success"
                         :to="`/waste-partners/price-mixes/manage/${item.id}`">
                    <v-icon>mdi-package-variant-closed</v-icon>
                  </v-btn>
                </template>
                <span>Prices</span>
              </v-tooltip>

              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small color="error" @click="showDeleteConfirmDlg(item, index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <YesNoDlg
        @yes="deletePartner()"
        @no="dlgDeleteConfirm = false"
        :visible="dlgDeleteConfirm"
        :width="350"
        :title="'Delete item'"
        :question="`You are about to delete ${currentPartner.company_name}.`"
        :processing="flagDeletingItem"
    />

    <axios ref="axios" v-on:finish="handleAxiosResponse($event)"/>
  </div>
</template>

<script>
import axios from "axios";

export default ({
  name: "WastePartners",

  data: () => ({
    operationResultMessage: "",
    operationResultColor: "",
    dlgDeleteConfirm: false,
    flagDeletingItem: false,
    flagLoadingItems: false,
    itemsHeaders: [
      {text: "Company Name", value: "company_name"},
      {text: "Address", value: "address"},
      {text: "Contact Person", value: "contact_person"},
      {text: "Email", value: "email"},
      {text: "Phone", value: "phone"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    breadcrumbs: [
      {text: "Waste partners", disabled: true}
    ],
    items: [],
    rvms: [],
    brands: [],
    manufacturers: [],
    materials: [],
    currentPartner: {},
    currentPartnerIndex: {},
    pagination: {},
    endpoints: {
      get: 'waste-partners/waste-partners/all',
      delete: 'waste-partners/waste-partners/delete',
    },
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    setPagination(event) {
      this.pagination = event
    },
    calculateItemIndex(index) {
      return (this.pagination.page - 1) * this.pagination.itemsPerPage + index
    },
    deletePartner() {
      this.flagDeletingItem = true

      let config = {
        method: 'delete',
        snackbar: false,
        params: {
          partnerId: this.currentPartner.id,
        },
        overlay: true,
        url: this.endpoints.delete,
      };

      this.$refs.axios.submit(config);
    },
    handleAxiosResponse(event) {
      this.loadingPrices = false;

      if (event.data.result.code === 200) {

        switch (event.url) {
          case this.endpoints.delete:
            this.flagDeletingItem = false
            this.dlgDeleteConfirm = false
            this.items.splice(this.currentPartnerIndex, 1)
            break;
          default:
            break;
        }
      }
    },
    showDeleteConfirmDlg(item, index) {
      this.currentPartner = item
      this.currentPartnerIndex = this.calculateItemIndex(index)

      this.dlgDeleteConfirm = true
    },
    showForm(index) {
      this.currentPartnerIndex = this.calculateItemIndex(index)

      let currItem = index >= 0
          ? this.items[this.currentPartnerIndex]
          : {}

      this.$router.push({
        name: 'WastePartnerFormView',
        params: {
          item: currItem,
          rvms: this.rvms,
          brands: this.brands,
          manufacturers: this.manufacturers,
          materials: this.materials,
        },
      })
    },
    getItems() {
      this.flagLoadingItems = true;
      let url = `${process.env.VUE_APP_BASE_API}waste-partners/waste-partners/all`;

      axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwt}`
            }
          })
          .then((response) => {
            this.flagLoadingItems = false;

            this.items = response.data.items;
            this.rvms = response.data.rvms;
            this.brands = response.data.brands;
            this.manufacturers = response.data.manufacturers;
            this.materials = response.data.materials;
          })
          .catch(() => {
            this.flagLoadingItems = false;
          });
    },
  }
});
</script>
